.t-dark {
  color: $primary-darker !important;

}
.btn-primary {
  color: $white;
  background: $primary !important;
  background-color: $primary !important;


  &:hover,
  &:focus {
    background: $primary-lighter !important;
    background-color: $primary-lighter !important;
    
    &::after {
      opacity: 1;
    }
  }

}
