

// ul {
//   list-style: none; /* Remove default bullets */
// }
ul li {
      line-height: 2;
}

ul li  {
  content: "";
}

.green-dots li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: $primary !important; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: .8em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

// body {
//   color:#77838f !important;
// }

.bg-green {
 background:  $primary-darker;
 background-color:  $primary-darker;
}


.line-height-title {
  line-height: 1.2em !important;
}


.support-block {
  .btn {

  @extend .btn-block;
}
}


.figure-para {
  /* Set a specific height */
 height: 500px;

 /* Create the parallax scrolling effect */
 background-attachment: fixed;
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
}

.parallax-child {
  transform-origin: 0 0;
  transform: translateZ(-122px) scale(3);
}
