
h1,h2,h3,h4,h5,h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
font-family: "Sanchez", Helvetica, Arial, sans-serif !important;
}


@include media-breakpoint-down(md) {

  h1, .h1  {
    font-size:1.8em !important;
      font-weight: 700!important;
}


h2
{
font-size:1.8em !important;
    font-weight: 700!important;}

.h4
{
font-size:1.2em !important;
    font-weight: 700!important;}
}
@include media-breakpoint-up(lg) {

  h1, .h1 {

  font-size:2.6em;
      font-weight: 700!important;

  }

}


.t-dark {
  color: $primary-darker !important;

}
.t-light {
  color: $primary-lighter !important;
}

.text-orange {
  color: $orange !important;

}

.text-default {
color:#1e2022;

}

.text-primary {
  color:$primary !important;
}


.alignright {
  float:right;
  margin:20px;
  margin-right:0px;
}

.cbp-filter-item-active {

  color:#7bb351 !important;

}

a { text-decoration: underline !important;
cursor: pointer;
color:#7bb351 !important;}
a.dv-fullscreen__nav-link,
a.btn {color:#ffffff !important;
text-decoration: none !important;
}

html {
  scroll-behavior: smooth;
}
