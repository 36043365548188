/*------------------------------------
  Hover Animation Effects
------------------------------------*/

// Reveal v1
.dv-cubeportfolio {
	&--reveal-v1 {
		.cbp-l-caption-body {
			transform: translateY(-100%);
			transition: $transition-timing-md $transition-function;
		}

		.cbp-caption {
			&:hover {
				.cbp-l-caption-body {
					transform: translateY(0%);
				}
			}
		}
	}
}